// esta es la pagina de gracias de contacto
import React from "react"
import Layout from "../../components/layout"
import Hero from "../../components/hero"
import { Helmet } from "react-helmet"
import Img from "../../assets/images/bgHeroBusesUrbanos.jpg"
import { BusUrbano } from "../../data/buses"
import BlockBusUrbano from "../../components/buses/blockTpBusesDesc"
export default function BusesUrbanos() {
  return (
    <>
      <Helmet>
        <title>Buses Urbanos</title>
        <meta name="description" content="Buses Urbanos de Intramet"></meta>
      </Helmet>
      <Layout>
        <Hero img={Img} title={BusUrbano.title}></Hero>
        <BlockBusUrbano data={BusUrbano}></BlockBusUrbano>
      </Layout>
    </>
  )
}
// para las imagenes en illustrator
