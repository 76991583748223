import styled from "styled-components"
import { Link } from "gatsby"
export const BusDetails = styled.div`
  .BusLink {
    width: 65%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @media (max-width: 700px) {
    .BusLink {
      width: 100%;
      img {
        height: auto;
      }
    }
  }
`
export const ItemLink = styled(Link)`
  align-self: flex-end;
  background-color: ${props =>
    props.color === "amarillo"
      ? props.theme.colors.azul
      : props.theme.colors.amarillo};
  padding-top: 10rem;
  width: 35%;
  text-align: right;
  span {
    border-top: 3px solid
      ${props =>
        props.color === "amarillo"
          ? props.theme.colors.amarillo
          : props.theme.colors.azul};
    color: ${props =>
      props.color === "amarillo"
        ? props.theme.colors.gris
        : props.theme.colors.azul};
    margin-right: 2rem;
    display: block;
    font-weight: bold;
    font-size: ${({ theme }) => theme.fontsize.medium};
    line-height: 2;
  }
  @media (max-width: 700px) {
    width: 100%;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    span {
      margin-right: 0;
    }
  }
`
export const BusDetailsItem = styled.div`
  display: flex;
  padding: 3rem 0;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`
